// Import fonts
@import url(//fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap|Roboto+Slab:700);
/* @import url(//use.typekit.net/nqn8uer.css); */

$font-family-base: 'Inter','Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family-serif: 'Inter','Helvetica Neue', Helvetica, Arial, sans-serif !default;
/* $font-family-serif: 'pt-serif-pro-1', 'pt-serif-pro-2',serif; */
$font-family-title: 'Inter','Helvetica Neue', Helvetica, Arial, sans-serif;

$white: #f5f5f5;
$black: #282828;
$blue:  #009aec;
$gray:  #d3cfcf;
$gray-comp: #828282;
$gray-dark: #4a4a4a;
$blue-dark: rgba(0,32,63,1);
$navbar: #009aec;
$body-bg: $black;
$body-color: #dedede;
$border-dark: #d3cfcf;
$link-hover-color: #009aec;
$link-hover-decoration: #009aec;
$table-cell-padding: 5px;

/* custom vars */

// Import partials.
@import
  "custom/_screen",
  "custom/_selection-sharer",
  "custom/_applause"
  ;
// don't forget that last item doesn't have a comma or the whole thing breaks
