/*
Template Name: shellen blocks
*/
body {
    font-family: $font-family-base;
}
.mainheading {
  padding: 1rem 0rem;
}

a {
  color: #00ab6b;
  transition: all 0.2s;
  &:hover {
    color: #038252;
    text-decoration: none;
  }
}

pre {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 0 1.75em 0;
  border: #E3EDF3 1px solid;
  width: 100%;
  padding: 10px;
  font-family: monospace, sans-serif;
  font-size: 14px;
  white-space: pre;
  overflow: auto;
  background: #F7FAFB;
  border-radius: 3px;
}

.mundananavigation {
  transition: top 0.2s ease-in-out;
}

.main-content {
  min-height: 300px;
}

.site-content {
  min-height: 60vh;
  padding-top: 1.5rem;
  margin-top: 57px;
  transition: all 0.4s;
}

section {
  margin-bottom: 20px;
  &.recent-posts {
    margin-bottom: 0;
  }
}

.section-title {
  h2 {
/*    border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 27px;
    font-family: $font-family-title;
  }
  span {
    border-bottom: 1px solid rgba(0, 0, 0, 0.44);
    display: inline-block;
    padding-bottom: 20px;
    margin-bottom: -1px;
  }
}

/* @media (min-width: 576px) {
  .card-columns.listfeaturedtag {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }
}
*/

.navbar-nav {
  font-family: $font-family-base;
  font-size: 0.9em;
  .navbar-item {
    font-size: 1.3em;
  }
}

.listfeaturedtag {
  border: 0px solid rgba(0, 0, 0, 0.125);
 /* border-radius: .25rem; */
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  .wrapthumbnail {
    min-height: 290px;
    flex: 0 0 auto;
    height: 100%;
  }
}

.maxthumb {
  max-height: 300px;
  overflow: hidden;
}

.listfeaturedtag .card, .card-footer {
  border: 0;
}

.listfeaturedtag {
  .thumbnail {
    background-size: cover;
    height: 100%;
    display: block;
    background-position: 38% 22% !important;
    background-origin: border-box !important;
/*    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem; */
  }
  .card-block {
    padding-left: 0;
  }
  h2.card-title {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.25;
    font-family: $font-family-base;
  }
}

.listrecent h2.card-title {
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.25;
  font-family: $font-family-base;
}

.listfeaturedtag h4.card-text, .listrecent h4.card-text {
  color: rgba(0, 0, 0, 0.44);
  font-size: 0.95rem;
  line-height: 1.4;
  font-weight: 400;
  font-family: $font-family-base !important;
}

.wrapfooter {
  font-size: 12px;
  font-family: $font-family-base;
}

.author-thumb {
  width: 40px;
  height: 40px;
  float: left;
  margin-right: 13px;
  border-radius: 100%;
}

.post-top-meta {
  margin-bottom: 2rem;
  font-family: $font-family-base;
  .author-thumb {
    width: 72px;
    height: 72px;
  }
  &.authorpage .author-thumb {
    margin-top: 40px;
  }
  span {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.44);
    display: inline-block;
  }
  .author-description {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 0.95rem;
  }
}

.author-meta {
  flex: 1 1 auto;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

span {
  &.post-name, &.post-date, &.author-meta {
    display: inline-block;
  }
  &.post-date, &.post-read {
    color: rgba(0, 0, 0, 0.44);
  }
  &.post-read-more {
    align-items: center;
/*    display: inline-block; */
    float: right;
    margin-top: 8px;
    a {
      color: rgba(0, 0, 0, 0.44);
    }
  }
  &.post-name a, &.post-read-more a:hover {
    color: rgba(0, 0, 0, 0.8);
  }
}

.dot:after {
  content: "·";
  margin-left: 3px;
  margin-right: 3px;
}

.mundanaavigation {
  .form-control {
    font-size: 0.8rem;
    border-radius: 30px;
    overflow: hidden;
/*    border: 1px solid rgba(0, 0, 0, 0.04); */
  }
  .form-inline {
    margin-left: 15px;
    .btn {
      margin-left: -50px;
      border: 0;
      border-radius: 30px;
      cursor: pointer;
      &:hover, &:active {
        background: transparent;
        color: green;
      }
    }
  }
  .navbar-brand {
    font-weight: 700;
    font-family: $font-family-title;
    color: $blue !important;
    font-size: 250%;
  }
  .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.08);
    margin: .5rem 0 0;
  }
  .nav-item {
    font-size: 0.9rem;
  }
}

.dropdown-menu {
  font-size: 0.9rem;
}

.mundananavigation {
  .search-icon {
    margin-left: -40px;
    display: inline-block;
    margin-top: 3px;
    font-size: 0.9em;
    cursor: pointer;
  }
  .navbar-brand img {
    max-height: 50px;
  }
}

.mainheading {
  border-left: solid 5px $gray;
  padding-left: 20px;
  padding-bottom: 30px;
  margin-left: -20px;
}
.mainheading h1 {
  font-family: $font-family-title;
 
  &.sitetitle {
  }
  &.posttitle {
    font-weight: 700;
    letter-spacing: -0.1rem;
    margin-bottom: 1rem;
  }
}

.postsubtitle {
  font-family: $font-family-base;
  color: $gray-comp;
  font-size: 1em;
  font-weight: 100;
  line-height: 1.8em;
}

.postedon {
  float: right;
  color: $gray-comp;
  font-size: .9em;
  font-family: $font-family-base;
  padding-left: 5px;
  margin-top: 60px;
}
.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
  padding-top: 15px;
  padding-bottom: 12px;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.44);
  margin-top: 50px;
  margin-bottom: 62px;
  position: relative;
  background: #fff;
}

.link-dark {
  color: rgba(0, 0, 0, 0.8);
}

.article-post {
  font-family: $font-family-serif;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: rgba(0, 0, 0, 0.8);

  .nav,th,tr,td{
      font-family: $font-family-base;
      font-size: .9em;
    }
  p, blockquote {
        margin: 0 0 1.5rem 0;
        font-weight: 400;
      }
 .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
       font-weight: 700;
        margin-bottom: 1.5rem;
        font-family: $font-family-title,
      }
  img.shadow {
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
      }
  ol, ul {
       margin-bottom: 1.5rem;
      }
  ol ol, ul ul {
       list-style: disc;
        margin-bottom: 0rem;
      }
  }

blockquote {
  border-left: 4px solid #005481;
  padding: 0 20px;
  font-style: italic;
  color: $gray-dark;
  line-height: 1.8em;
  font-size: 120%;
  font-family: $font-family-serif;
}

.reading {
  color: $gray-dark;
}

.video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  min-width: 320px;
  height: 0;
  overflow: hidden;
  margin-bottom: 15px;
  margin-left: -15px;
  margin-right: -15px;

iframe, object, embed {
position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

cite {
  quotes: ‘„’ ‘“’;
	}
}

q {
  font-size: 175%;
  line-height: 1.5em;
  padding: 20px;
  margin-left: -15px;
  margin-right: -15px;
  font-weight: 400;
  font-family: $font-family-serif;
  background-color: #005481;
  color: $white;
  display: block;
  border: none;
  min-height: 150px;
  align-content: center;
}

.featured-image {
  display: block;
  margin-bottom: 1.5rem;
}

.share {
  text-align: center;
  p {
    margin-bottom: 10px;
    font-size: 0.95rem;
  }
  .applause-button {
    margin-top: 20px;
    line-height: 50px;
  }

  ul {
    li {
      display: inline-block;
      margin-bottom: 9px;
      i.fa {
        border: 0px solid #ddd;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 50%;
      }
    }
    padding-left: 0;
    margin-left: 0;
  }
}

.svgIcon {
  vertical-align: middle;
}

@media (min-width: 1024px) {
  .share {
    position: fixed;
    display: block;
    ul li {
      display: block;
    }
  }
}

/* @media (max-width: 999px) {
  .alertbar {
    position: relative !Important;
    margin-bottom: 20px;
    margin-top: 20px;
    box-shadow: none !Important;
    padding-right: 14px !Important;
  }
  #comments {
    margin-right: 15px;
  }
  .jumbotron.fortags {
    margin-bottom: 0 !Important;
  }
  .alertbar {
    form {
      margin-top: 20px;
    }
    span, form {
      display: block;
    }
    input {
      &[type="submit"] {
        border-radius: 3px !Important;
      }
      &[type="email"] {
        margin-right: 0px !Important;
        display: block;
        border-right: 1px solid #ddd !Important;
        margin-bottom: 10px;
      }
    }
  }
  .jumbotron {
    margin-bottom: 0;
    border-radius: 0;
  }
  .listfeaturedtag {
    .wrapthumbnail, .col-md-7 {
      width: 100%;
      max-width: 100%;
      -webkit-box-flex: 0;
      -webkit-flex: 100%;
      -ms-flex: 100%;
      flex: 100%;
      border: 0;
    }
    .wrapthumbnail {
      height: 250px;
    }
    .card {
      height: auto;
    }
    .wrapfooter {
      position: relative;
      margin-top: 30px;
    }
    .card-block {
      padding: 20px;
    }
  }
  .footer {
    margin-top: 0px;
    margin-bottom: 0px;
  }
} */

@media (max-width: 1024px) {
  .post-top-meta .col-md-10 {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .post-top-meta.authorpage {
    text-align: center;
  }
}

.share {
  color: rgba(0, 0, 0, 0.44);
  fill: rgba(0, 0, 0, 0.44);
  a {
    color: rgba(0, 0, 0, 0.44);
    fill: rgba(0, 0, 0, 0.44);
  }
}

.graybg {
  background-color: #fafafa;
  padding: 40px 0 46px;
  position: relative;
}

.listrelated .card {
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.05); 
  border: 0;
}

ul.tags {
  list-style: none;
  padding-left: 0;
  margin: 0 0 3rem 0;
  li {
    display: inline-block;
    font-size: 0.9rem;
    a {
      background: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.6);
      border-radius: 3px;
      padding: 5px 10px;
      &:hover {
        background: rgba(0, 0, 0, 0.07);
        text-decoration: none;
      }
    }
  }
}

.margtop3rem {
  margin-top: 3rem;
}

.sep {
  height: 1px;
  width: 20px;
  background: #999;
  margin: 0px auto;
  margin-bottom: 1.2rem;
}

.btn {
  &.follow {
    border-color: $blue;
    color: $blue;
    padding: 2px 10px;
    text-align: center;
    border-radius: .9 rem /*999em*/;
    font-size: 0.85rem;
    display: inline-block;
  }
  &.subscribe {
    background-color: #1C9963;
    border-color: #1C9963;
    color: rgba(255, 255, 255, 1);
    fill: rgba(255, 255, 255, 1);
    border-radius: 30px;
    font-size: 0.85rem;
    margin-left: 10px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.post-top-meta .btn.follow {
  margin-left: 5px;
  margin-top: -4px;
}

.alertbar {
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.0785);
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  padding: 14px 0;
  z-index: 1;
  display: none;
  font-family: $font-family-base;
  form {
    display: inline-block;
  }
  input {
    &[type="email"] {
      font-size: 0.85rem;
      padding: 3px 5px 3px 10px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border: 1px solid #ddd;
      border-right: 0;
      margin-right: -10px;
      height: 34px;
      letter-spacing: 0.5px;
      margin-left: 5px;
    }
    &[type="submit"] {
      background-color: $blue;
      border: 1px solid $blue;
      color: rgba(255, 255, 255, 1);
      fill: rgba(255, 255, 255, 1);
      font-size: 0.85rem;
      border-radius: 0;
      padding: 4px 10px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      font-weight: 700;
      height: 34px;
      letter-spacing: 0.5px;
      cursor: pointer;
      font-family: $font-family-base;
    }
  }
}

.form-control {
  &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
}

.authorpage h1 {
  font-weight: 700;
  font-size: 30px;
}

.post-top-meta.authorpage .author-thumb {
  float: none;
}

.authorpage .author-description {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.6);
}

.post-top-meta.authorpage .btn.follow {
  padding: 7px 20px;
  margin-top: 10px;
  margin-left: 0;
  font-size: 0.9rem;
}

.graybg.authorpage {
  border-top: 1px solid #f0f0f0;
}

.authorpostbox {
  width: 760px;
  margin: 0px auto;
  margin-bottom: 1.5rem;
  max-width: 100%;
  .img-thumb {
    width: 100%;
  }
}

.sociallinks {
  margin: 1rem 0;
  a {
    background: #666;
    color: #fff;
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    line-height: 22px;
    border-radius: 50%;
    font-size: 12px;
  }
}

#comments {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-base, -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
}

img {
  max-width: 100%;
}

.bottompagination span.navigation {
  display: block;
  font-size: 0.93rem;
  padding: 15px 0 0 0;
  text-align: center;
  margin-bottom: 0rem;
  color: #999;
  border-top: 1px solid #ddd;
}

.pointerup {
  margin-bottom: -17px;
  margin-left: 49%;
  font-size: 30px;
  i.fa {
    color: #eaeaea;
  }
}

.bottompagination span.navigation i {
  display: inline-block;
}

span.navigation {
  display: inline-block;
  font-size: 0.93rem;
  font-weight: 700;
  text-align: center;
}

.pagination {
  display: block;
}

iframe {
  max-width: 100%;
}

.transpdark {
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
}

@media (min-width: 768px) {
  .jumbotron.fortags {
    margin-bottom: -50px;
    margin-top: 3rem;
    padding: 0;
    height: 350px;
    border-radius: 0;
/*    background-image: url(../images/jumbotron.jpg); */
    background-size: cover;
    .col-md-4 {
      background: rgba(0, 0, 0, 0.75);
      color: #fff;
    }
    .row {
      margin: 0;
    }
  }
}

.jumbotron.fortags {
  margin-top: 3rem;
  padding: 0;
  border-radius: 0;
/*  background-image: url(../images/jumbotron.jpg); */
  background-size: cover;
  a {
    padding: 5px 10px 7px;
    background: #222;
    border-radius: .25rem;
    color: #fff;
    font-weight: 500;
    text-transform: lowercase;
    font-size: 0.8rem;
  }
}

.layout-page .jumbotron.fortags {
  display: none;
}

.mb-30px {
  margin-bottom: 30px;
}

.flex-first {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
}

@media (min-width: 768px) {
  .flex-md-unordered {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .flex-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
}

/* @media (max-width: 768px) {
  .share {
    margin-top: 30px;
  }
} */
.card .card-body {
  font-family: $font-family-base;
}

.card h5 {
    font-family: $font-family-base;
}
.card .img-fluid {
  width: 100%;
}

.homepromo {
  background-color: $blue !important;
  border: none;
}

.heart {
  background-color: $blue;
}

.bg-gray {
  background-color: #F7FAFB;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.form-group label{
  padding-left: 12px;
  padding-top: 5px;
}

.form-group-row {

  label,textarea{
  padding-left: 12px;
  padding-top: 5px;
}
}

#instafeed {
  overflow: auto; margin-left: -1%;
   a {
    float: left; display: block; margin: 0 0 1% 1%; width: 19%;
    img {
      width: 100%;
    }
  }
}
